import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import { graphql } from 'gatsby'

const DefaultTemplate = ({ data }) => (
  <Layout data={data} />
)

DefaultTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default DefaultTemplate

export const query = graphql`
  query DefaultTemplateQuery($id: String) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
  }
`
